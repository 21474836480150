/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

a.titlelink {
  @apply no-underline transition duration-300 ease-in-out shadow-titlelink;
}

a.titlelink:hover {
  @apply shadow-titlehover;
}
